import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetFollowUpClients } from 'services/ClientsService';

// On d�finit un thunk pour r�cup�rer les clients � relancer
export const fetchFollowUpClients = createAsyncThunk(
  'followUpClients/fetch',
  async (params, thunkAPI) => {
    const response = await apiGetFollowUpClients(params);
    // On suppose que la r�ponse a la forme { data: { data: [...] } }
    return response.data.data;
  }
);

const followUpClientsSlice = createSlice({
  name: 'followUpClients',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Vous pouvez ajouter d'autres reducers si n�cessaire
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFollowUpClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFollowUpClients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFollowUpClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default followUpClientsSlice.reducer;
