// src/FollowUpInitializer.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFollowUpClients } from 'store/followUpClientsSlice';

const FollowUpInitializer = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (user?.u_id) {
      dispatch(fetchFollowUpClients({ courtier_id: user.u_id, pageSize: 100, pageIndex: 1 }));
    }
  }, [user, dispatch]);

  return null; // Ce composant n'affiche rien, il initialise juste le state.
};

export default FollowUpInitializer;
