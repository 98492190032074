import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import shared from './shared'
import followUpClients from './followUpClientsSlice'; // Ajoutez cette ligne

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        shared,
        followUpClients,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
 