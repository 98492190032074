import axios from "axios"
import ApiService from "./ApiService"
import apiConfig from "configs/api.config"

export async function apiGetCrmDashboardData (data) {
    return ApiService.fetchData({
        url: '/crm/dashboard',
        method: 'get',
        data
    })
}

export async function apiGetClients(params) {
    return ApiService.fetchData({
        url: '/clients',
        method: 'get',
        params: params
    })
}

export async function apiGetClientsTable(params) {
    return ApiService.fetchData({
        url: '/clients/query',
        method: 'get',
        params: params
    })
}

export async function apiGetLastClientsTable(params) {
    return ApiService.fetchData({
        url: '/clients/last-clients-table',
        method: 'get',
        params: params
    })
}

export async function apiGetPendingApprovalClients(params) {
    return ApiService.fetchData({
        url: '/clients/pending-approval-clients',
        method: 'get',
        params: params
    })
}


export async function apiGetClient (params) {
    return ApiService.fetchData({
        url: '/clients/' + params.id,
        method: 'get',
        params: params
    })
}
export async function apiCreateClient(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/clients/new',
            method: 'post',
            data,
        });
        console.log("R�ponse de l'API : ", response);
        return response;
    } catch (error) {
        console.log("Erreur dans apiCreateClient : ", error);

        if (error.response && error.response.status === 400) {
            // Retourne les erreurs de validation
            const validationErrors = error.response.data.messages || {};
            console.log("Erreurs de validation retourn�es par l'API :", validationErrors);
            throw validationErrors; // Propager pour �tre attrap� par le `catch` de `handleFormSubmit`
        }

        throw error; // Relancer toute autre erreur
    }
}


export async function apiPutClient (id, data) {
    return ApiService.fetchData({
        url: '/clients/' + id + '/update', 
        method: 'post',
        data
    })
}



export async function apiDeleteClient (data) {
    return ApiService.fetchData({
        url: '/clients/' + data.id + '/delete', 
        method: 'post',
        data
    })
}

export async function apiGetEnterpriseData(query, limit = 1) {
    return axios.get(apiConfig.entrepriseSearchApi, { 
        params : {
        q : query,
        page : 1,
        per_page : limit
        }
     })
}


export async function apiGetFollowUpClients(params) {
    return ApiService.fetchData({
        url: '/clients/follow-up',
        method: 'get',
        params: params,
    });
}

export async function apiUpdateClientFollowUp(clientGuid, data) {
  return ApiService.fetchData({
    url: `/clients/${clientGuid}/follow-up`, 
    method: 'post',
    data
  })
}

export async function apiGetFollowUpHistory(clientGuid,params) {
  return ApiService.fetchData({
    url: `/clients/follow-up-history/${clientGuid}`,
    method: 'get',
    params,
  });
}